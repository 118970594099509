<template>
    <el-dialog v-model="isShowDeviceList" width="1200px" :draggable="true" :destroy-on-close="true">
        <template #header>
            商户名称：{{ merchantInfo.merchant_name }}
        </template>
        <div class="table-head">
            <span class="table-title">已绑定设备列表</span>
            <span class="table-btn">
                <el-button @click="bandNewDevice" type="primary">
                    <el-icon style="color:#fff">
                        <Plus />
                    </el-icon>
                    <span style="margin-left:15px">绑定新设备</span>
                </el-button>
            </span>
        </div>
        <div class="table-main">
            <c-table ref="deviceTable" :tableDataUrl="deviceTableDataUrl" :isLoadData="false">
                <el-table-column type="index" width="55" label="序号" :align="'center'" />
                <el-table-column label="设备序列号">
                    <template #default="scope">
                        <div class="icon-box">
                            <el-tooltip effect="dark" :content="scope.row.serial_number" placement="top-start">
                                <span class="text-overflow">{{ scope.row.serial_number }}</span>
                            </el-tooltip>
                            <span class="copy icon">
                                <el-icon @click="copyWord(scope.row.serial_number)">
                                    <CopyDocument />
                                </el-icon>
                            </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="设备密钥">
                    <template #default="scope">
                        <div class="icon-box">
                            <el-tooltip effect="dark" :content="scope.row.secret" placement="top-start">
                                <span class="text-overflow">{{ scope.row.secret }}</span>
                            </el-tooltip>
                            <span class="copy icon">
                                <el-icon @click="copyWord(scope.row.secret, 'secret')">
                                    <CopyDocument />
                                </el-icon>
                            </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="机器码">
                    <template #default="scope">
                        <span v-if="scope.row.mark">
                            {{ scope.row.mark }}
                        </span>
                        <span v-else>
                            -
                        </span>
                    </template>
                </el-table-column>>
                <el-table-column label="设备分类">
                    <template #default="scope">
                        <el-tag>{{ deviceCategories[scope.row.device_category_id].name }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="设备型号">
                    <template #default="scope">
                        <el-tag type="warning">
                            {{ deviceCategories[scope.row.device_category_id].device_types[scope.row.device_type_id]
                                ? deviceCategories[scope.row.device_category_id].device_types[scope.row.device_type_id].name
                                : deviceCategories[scope.row.device_category_id].name }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="store.name" label="所属门店" />
                <el-table-column label="创建时间">
                    <template #default="scope">
                        {{ timeFormat(scope.row.created_at) }}
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template #default="scope">
                        <!-- <el-popconfirm title="确认解除绑定吗？" @confirm="unbind(scope.row)">
                            <template #reference>
                                <el-link type="primary">解绑</el-link>
                            </template>
                        </el-popconfirm> -->
                        <span style="margin-left:15px">
                            <el-link type="primary" @click="distributionStore(scope.row)">分配门店</el-link>
                        </span>
                    </template>
                </el-table-column>
            </c-table>
        </div>
    </el-dialog>
    <el-dialog v-model="isShowdistributionStore" width="400px">
        <template #header>
            分配门店
        </template>
        <el-row>
            <el-col :span="18">
                <el-form-item label="门店" :rules="[{ required: true, message: '此项必填' }]">
                    <el-select v-model="storesForm.store_id">
                        <el-option v-for="store in stores" :key="store" :label="store.name" :value="store.id" />
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
        <template #footer>
            <el-button @click="cancelDistributionStore">取消</el-button>
            <el-button type="primary" @click="submitlDistributionStore">确定</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="isShowNewDeviceList" width="1200px">
        <template #header>
            请选择需要绑定的设备
        </template>
        <c-table ref="bindDeviceTable" :tableDataUrl="bindDeviceTableDataUrl" :isLoadData="false"
            @selectionRows="selectionRows">
            <el-table-column type="selection" width="55" />
            <el-table-column type="index" width="55" label="序号" :align="'center'" />
            <el-table-column label="设备序列号">
                <template #default="scope">
                    <div class="icon-box">
                        <el-tooltip effect="dark" :content="scope.row.serial_number" placement="top-start">
                            <span class="text-overflow">{{ scope.row.serial_number }}</span>
                        </el-tooltip>
                        <span class="copy icon">
                            <el-icon @click="copyWord(scope.row.serial_number)">
                                <CopyDocument />
                            </el-icon>
                        </span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="设备密钥">
                <template #default="scope">
                    <div class="icon-box">
                        <el-tooltip effect="dark" :content="scope.row.secret" placement="top-start">
                            <span class="text-overflow">{{ scope.row.secret }}</span>
                        </el-tooltip>
                        <span class="copy icon">
                            <el-icon @click="copyWord(scope.row.secret, 'secret')">
                                <CopyDocument />
                            </el-icon>
                        </span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="机器码">
                <template #default="scope">
                    <span v-if="scope.row.mark">
                        {{ scope.row.mark }}
                    </span>
                    <span v-else>
                        -
                    </span>
                </template>
            </el-table-column>>
            <el-table-column label="设备分类">
                <template #default="scope">
                    <el-tag>{{ deviceCategories[scope.row.device_category_id].name }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="设备型号">
                <template #default="scope">
                    <el-tag type="warning">
                        {{ deviceCategories[scope.row.device_category_id].device_types[scope.row.device_type_id]
                            ? deviceCategories[scope.row.device_category_id].device_types[scope.row.device_type_id].name
                            : deviceCategories[scope.row.device_category_id].name }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column label="创建时间">
                <template #default="scope">
                    {{ timeFormat(scope.row.created_at) }}
                </template>
            </el-table-column>
        </c-table>
        <template #footer>
            <el-button @click="cancelBindDeviceTable">取消</el-button>
            <el-button @click="submitBindDeviceTable" style="margin-left:15px" type="primary">确定</el-button>
        </template>
    </el-dialog>
</template>

<script setup>
import { defineExpose, ref, getCurrentInstance } from 'vue';
import cTable from '@/components/CustomTable'
import { nextTick } from 'process';
import { Plus, CopyDocument } from '@element-plus/icons-vue'
import { timeFormat } from '@/func/time'
import { ElMessage } from 'element-plus';

const { proxy } = getCurrentInstance() // 当前实例

const deviceTable = ref(null)
const bindDeviceTable = ref(null)

const isShowDeviceList = ref(false)
const isShowdistributionStore = ref(false)
const isShowNewDeviceList = ref(false)

const merchantInfo = ref(null)
const deviceTableDataUrl = ref('')
const bindDeviceTableDataUrl = proxy.$api.merchant.device.unBindDeviceList

const deviceCategories = ref([])

// const deviceNumberCopy = ref(true)
// const deviceSecretCopy = ref(true)

// const deviceNumberList = ref([])
// const deviceSecretList = ref([])

const newDeviceSelectRows = ref([])

const stores = ref(null)
const storesForm = ref({
    store_id: ''
})

const device = ref(null)
// const unbind = (item) => {
//     const device_ids = [item.id]
//     proxy.$api.merchant.device.unBindStore(item.merchant_id, { device_ids: device_ids }).then(r => {
//         if (r.status == 200) {
//             ElMessage({ message: '解除绑定', type: 'success' })
//             deviceTable.value.refresh()
//             isShowdistributionStore.value = false
//         } else {
//             ElMessage({ message: r.error.message, type: 'error' })
//         }
//     })
// }

const distributionStore = (item) => {
    device.value = item
    isShowdistributionStore.value = true
    proxy.$api.merchant.store.list({ merchant_id: item.merchant_id, page: 1, pageSize: 999 }).then(r => {
        stores.value = r.data
        storesForm.value.id = item.id
        if (item.store && item.store.id) {
            storesForm.value.store_id = item.store.id
        } else {
            storesForm.value.store_id = ''
        }
    })
}

const cancelDistributionStore = () => {
    isShowdistributionStore.value = false
}

const submitlDistributionStore = () => {
    proxy.$api.merchant.device.bindStore(device.value.id, storesForm.value).then(r => {
        if (r.status == 200) {
            deviceTable.value.refresh()
            isShowdistributionStore.value = false
        } else {
            ElMessage({ message: r.error.message, type: 'error' })
        }
    })
}

const bandNewDevice = () => {
    isShowNewDeviceList.value = true
    console.log(merchantInfo.value)
    nextTick(() => {
        bindDeviceTable.value.setCustomLoad(true)
        bindDeviceTable.value.setParams({ is_bind_merchant: 0, agent_id: merchantInfo.value.agent_id })
    })
}

const cancelBindDeviceTable = () => {
    isShowNewDeviceList.value = false
}

const selectionRows = (rows) => {
    newDeviceSelectRows.value = rows
}

const submitBindDeviceTable = () => {
    const deviceIds = []
    newDeviceSelectRows.value.forEach(element => {
        deviceIds.push(element.id)
    })

    proxy.$api.merchant.device.bindDevices(merchantInfo.value.id, { device_ids: deviceIds }).then(r => {
        if (r.status == 200) {
            ElMessage({ message: "绑定成功", type: 'success' })
            isShowNewDeviceList.value = false
            deviceTable.value.refresh()
        } else {
            ElMessage({ message: r.error.message, type: 'error' })
        }
    })
}

const copyWord = (word) => {
    let tag = document.createElement('textarea');
    tag.setAttribute('id', 'cp_hgz_input');
    tag.value = word;
    document.getElementsByTagName('body')[0].appendChild(tag);
    document.getElementById('cp_hgz_input').select();
    document.execCommand('copy');
    document.getElementById('cp_hgz_input').remove();

    ElMessage({ message: '复制成功', type: 'success' })
}

const open = (item) => {
    isShowDeviceList.value = true
    merchantInfo.value = item

    proxy.$api.merchant.device.deviceCategory().then(r => {
        r.data.forEach((element) => {
            let deviceTypes = []
            element.device_types.forEach(elem => {
                deviceTypes[elem.id] = elem
            })
            element.device_types = deviceTypes
            deviceCategories.value[element.id] = element
        })
    })

    nextTick(() => {
        deviceTable.value.setCustomLoad(true)
        deviceTable.value.setRequestUrl('/agent/merchants/' + item.id + '/devices')
        deviceTable.value.setParams({ id: item.merchant_id })
    })
}

defineExpose({ open })
</script>

<style lang="less" scoped>
.icon-box {
    display: flex;
    flex-direction: row;

    .text-overflow {
        width: 50%;
        overflow: hidden;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
    }

    .copy {
        color: #409EFF !important;
        cursor: pointer;
    }

    .copyed {
        color: #52c41a !important;
    }

    .icon {
        font-size: 14px !important;

    }
}
</style>